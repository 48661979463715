
  .date-picker-modal {
    margin-top:9%;
    margin-right:0%;
    width: 100%;
    height:100%;
    
  }


  .date-picker-modal-on-date-click {
    margin-top: 0;
  }
  
  .date-picker-content {
    display: flex;
    flex-direction: column;
    width:100%;
  
  }

@media screen and (max-width:600px){
  .date-picker-content {
    width:160%;
  }
}

@media screen and (max-width:400px){
  .date-picker-content {
    width:180%;
  }
}
  
  .date-picker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    

  }

  .date-btn-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width:60%;
  }
  
  .date-range-display {
    display: flex;
    align-items: center;
    border: 1px solid #0046FF;
    border-radius: 0.5rem;
    justify-content: flex-end;
    width:50%;


  }

  
  .date-range-display input {
    border: none;
    outline: none;
    font-size: 1rem;
    
  }

  .duration-menu-items {
    display: flex;
    flex-direction: column;
    width:100%;

  }

  
  .done-btn {
    background-color: #0046FF !important;
    color: #FFFFFF !important;
    padding:  0.55rem !important;
    font-size: 0.9rem !important;

  }
  
  .calendars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width:100%;

  }

  .calendar {
    font-size: 1rem;
    width: 100%;
    padding:0rem 3rem 1rem 1rem;
  }
  .radio-button{
    color: #0046FF !important;
  }

  .calendar-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    
  }
  
  .calendar-header h3 {
    margin: 0;
    font-size: 1rem;
    color: #333;

  }

  .day {
    display: flex;
    justify-content: center;
    align-items: center;
    height:2rem;
    width:2rem; 
    cursor: pointer;
    font-size: 0.9rem;

  }
  .selected-date{
    font-size: 0.9rem;
  }
  @media screen  and (min-width:2560px){
    .calendar {
      font-size: 1.2rem;    
      padding:0rem 1.5rem 1rem 1.5rem;
    }
    .day{
      font-size: 1.2rem;
      padding:1.4rem;

    }
    .done-btn {
      font-size: 1.2rem !important;
    }
    .selected-date{
      font-size: 1.5rem;
    }
  }
  
  .day:hover {
    background-color: #f0f0f0;
  }
  
  .day.selected {
    background-color: #0046FF;
    color: #FFFFFF;
  }

  .day.selected.first {
    background-color: #0046FF;
    color: #FFFFFF;
  }
  
  .day.selected.last {
    background-color: #0046FF; 
    color: #FFFFFF;
  }
  
  .day.other-month {
    color: #ccc;
  }
  
  .nav-button {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    align-self: center;
  }


  
  .nav-button.prev {
    left: -1.2rem;

  }
  
  .nav-button.next {
    right: -1.2rem;

  }
  
  .nav-button svg {
    color: #0046FF !important;

  }
  .custom-date-range {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding-left: 0.4rem;
    font-size: 0.9rem;
    padding-right: 0.8rem;
    border:1px solid #979797;
    padding-top: 0.5rem;
    padding-bottom: 0.45rem;
    border-radius: 0.5rem;

  }

  
  .custom-date-range svg {
   margin-right: 0px;
   
  }


  .MuiRadioGroup-root {
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
  }
  
  .MuiFormControlLabel-root {
    margin: 0;
    width: 45%;
    }
  
  .MuiFormControlLabel-root:last-child {
    border-bottom: none;
    width:50%;

  }
  
  .MuiRadio-root {
    padding: 1px;
  }
  
  .MuiFormControlLabel-label {
    padding: 0.8rem 0;
    font-size: 0.85rem !important;

  }


  .MuiSelect-select {
    display: flex;
    align-items: centefr;
  }

  
  .MuiMenuItem-root {
    display: flex;
    align-items: center;
  }
  .date-picker-popover {
    padding: 1rem;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    height:14rem;
    width:60vw
    
  }

  .duration-picker-divider{
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    width:100%;
  }


  .custom-form-control-label .MuiFormControlLabel-label {
    font-size: 1rem !important ;
  }


  
  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.1rem;       
  }
  @media screen and (min-width:2560px){
    .date-picker-popover{
      width:40vw

    }
    .custom-form-control-label .MuiFormControlLabel-label {
      font-size: 1.4rem !important ;
    }

    .days{
      gap:0.5rem;
    }
    
  }

  .date-type-selector {
    display: flex;
    align-items: center;    
    width:25vw;
  }

  @media screen and (max-width:600px){
    .date-type-selector{
      width:57vw;
    }
  }

  
  
  .month-year-selector {
    display: flex;
    
  }

  .duration-select-component{
    background-color: #FFFFFF;
    height: 2.8rem;
   
  }

.day.disabled:hover {
  background-color: transparent;
}



.calendar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  
}
.custom-date-picker{
  height: 19rem;

}

.Mui-checked {
  color: #0046FF !important;
}

.custom-date-picker button{
  font-size: 0.9rem;
  color:#0046FF
}

.custom-date-picker .Mui-selected{
  color:#FFFFFF !important;
  background-color: #0046FF !important;


}
.custom-date-picker .MuiButton-root{
  background-color: #0046FF !important;
}


.day.today {
  background-color: #e0e0e0;
}

.day.selected.start {
  background-color: #0046FF;
  color: white;
}

.day.selected.end {
  background-color: #0046FF;
  color: white;
}

.day.selected.middle {
  background-color: #E5EDFF;
  color: #0046FF;
}

.day.selected.start.end {
  border-radius: 0%;
}

.day.disabled {
  color: #bdbdbd;
  cursor: not-allowed;
}
