.chart-container {
    background-color:lightblue;
    border-radius: 10px;
    width:300px;
    padding: 16px;
  }
  
  .custom-tooltip {
    background-color: white;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
  }
  
  .custom-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  .custom-tooltip p {
    margin: 0;
    font-weight: bold;
  }
  
  .cell-hover {
    transform: scale(1.05);
    transition: transform 0.4s ease-in-out;
  }

  .recharts-sector:hover {
    transition: transform 0.4s ease-in-out;
  }


  .recharts-wrapper:focus {
    outline: none;
  }
  
  .recharts-sector:focus {
    outline: none;
    border: none;
  }