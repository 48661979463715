.map-chart-container{
    width: 100%;
    box-sizing: border-box;
    border-radius: 2%;
    background-color: #ffffff;
    margin: 2% 4% 2% 4%;
    padding: 2% 4% 2% 4%;
}


.gradient-text {
  font-family: 'Aeonik', sans-serif;
  font-size: 12px;
  fill: rgb(29, 7, 109);
}

.main-container {
  position: relative;
  flex: 1;
  width: 100%;
}


.map-container {
  height: 90%;
  width: 100%;
  position: relative;
}

.tooltip {
  position: absolute;
  pointer-events: none;
  background-color: #FFFFFF;
  padding: 0.5rem;
  color: blue;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  z-index: 1;
}


.pin-container{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pin {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  white-space: nowrap;
  box-shadow: 6.33px 7.69px 18.09px 1.81px #7090B099;
  box-shadow: 0px 4.52px 18.09px 0px #00000033;
}
.pin-container::after {
  content: 'pin';
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 100;
  height: 100;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #E4E7EC;
  z-index: -1;
}

.pin-text-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.pin-flag {
  width: 15px;
  height:15px;
  background-color: #E3F2FD;
  margin-right: 0.1rem;
}

.pin-country {
  color: #475467;
  font-size: 0.7rem;
}

.pin-count {
  font-size: 0.9rem;
}

.gradient-bar {
  position: absolute; 
  height: 100%;
  width:20%;
  bottom: -42%;
  right: -13%;
}

.gradient-rectangle {
  position: absolute;
  background: linear-gradient(135deg, #FBDA61 2.88%, #FF4B55 98.13%);
  width: 40px;  
  height:50%; 
}

.min-label,
.max-label {
  font-size: 0.75rem !important;
  position: absolute;
  bottom:100%;
  right: 60%;
  white-space: nowrap;
}

.min-label {
  bottom: 12;
  transform: translateX(60%); 
}

.max-label {
  right: 90%;
  bottom: -10%;
  transform: translateX(100%); 
}

.max_label-single-digit {
  right: 65%;
  bottom: -8%;
}
@media screen and (max-width: 600px) {
  .gradient-bar {
    width: 60%;
    height: 40px;
    bottom: 20px;
    right: 50%;
    transform: translateX(50%);
  }

  .gradient-rectangle {
    width: 100%;
    height: 20px;
    background: linear-gradient(90deg, #FBDA61 2.88%, #FF4B55 98.13%);
  }

  .min-label {
    bottom: auto;
    left: -20%;
    top:-20%;
    right: auto;
    transform: translateY(50%); 
  }

  .max-label {
    bottom: auto;
    right: -25%;
    top: -25%;
    transform: translateY(50%); 

  }
}


@media screen and (max-width: 600px) {
  .gradient-bar {
    width: 60%;
    height: 40px;
    bottom: 20px;
    right: 50%;
    transform: translateX(50%);
  }

  .gradient-rectangle {
    width: 100%;
    height: 20px;
    background: linear-gradient(90deg, #FBDA61 2.88%, #FF4B55 98.13%);
  }

  .min-label {
    bottom: auto;
    left: -10%;
    top:-20%;
    right: auto;
    transform: translateY(50%); 
  }

  .max-label {
    bottom: auto;
    right: -25%;
    top: -19%;
    transform: translateY(25%); 

  }
  .max_label-single-digit {
    right: -12%;
    bottom: -8%;
  }
}

@media screen and (max-width: 400px) {
  .min-label {
    left: -15%;
    top:-20%;
    transform: translateY(50%); 
  }

  .max-label {
    right: -30%;
    top: -20%;
    transform: translateY(25%); 

  }
  .max_label-single-digit {
    right: -12%;
    bottom: -8%;
  }
}


@media screen and (min-width:2560px) {
  .max-label {
    font-size: 1.2rem !important;
  }
  .min-label {
    font-size: 1.2rem !important;
  }
  
}


.country-label {
  transition: z-index 0.8s ease-in-out;
}

.country-label:hover {
  cursor: pointer;
}
