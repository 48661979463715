.sidebar_menu-container{
  height:85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top:1.5rem;
}

.sidebar_menu-icon path{
  fill:#FFFFFF;
}
.sidebar_dashboard-icon path{
  fill:#0046FF;
}



.sidebar_icon{
  padding-left: 0.1rem;
}



.logout-icon path{
  stroke:#FFFFFF !important;
}


 .MuiListItemIcon-root{
    min-width:2% !important;  
  }

.sidebar_menu-profile-icon{
  display: flex;
  align-items: center;
  width:2.8rem !important;
  height:2.8rem !important;
  margin-left: 0.6rem !important;
  background-color:#E3F2FD;
  border-radius: 0.5rem;
  padding:6px !important;
  cursor: pointer;
}



@media screen and (min-width:2560px) {
  .sidebar_icon svg{
    height:2rem;width:2rem;
  }
  .sidebar_icon{
    padding-left: 0rem;
  }
  .logout-icon-svg svg{
    height:2rem;width:2rem;
  }
  .sidebar_menu-profile-icon{
    width:3.4rem !important;
    height:3.4rem !important;
  }
  
}
