.eula_loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.eula_loader {
  width: 60px;
  aspect-ratio: 1;
  border: 1px solid #ddd;
  border-radius: 50%;
  position: relative;
  transform: rotate(45deg);
}
.eula_loader::before {
  content: "";
  position: absolute;
  inset: -15px;
  border-radius: 50%;
  border: 10px solid #0046FF;
  animation: l18 2s infinite linear;
}
@keyframes l18 {
    0%   {clip-path:polygon(50% 50%,0 0,0    0,0    0   ,0    0   ,0    0   )}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0   ,100% 0   ,100% 0   )}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    0   )}
}